<template>
  <card-container backButton closeButton>
    <div class="flex flex-col items-center px-12 py-6 mt-16">
      <div class="text-3xl text-center">Slett bruker</div>
      <div class="mt-6">
        Eventuelle steder du har delt slettes ikke automatisk. Hvis du ønsker å slette disse må du
        gjøre dette manuelt før du sletter brukeren din.
        <br /><br />
        <span class="font-bold">
          OBS! Det er ikke mulig å gjenopprette en slettet bruker så tenk deg godt om :-)
        </span>
      </div>
      <form @submit.prevent="deleteUser" class="flex flex-col items-center w-full mt-6">
        <input ref="fileInput" type="file" class="hidden" accept="image/*" @change="onFilePicked" />
        <input
          type="password"
          ref="passwordInput"
          v-model="password"
          placeholder="Passord"
          class="w-full p-2 rounded-lg focus:outline-none"
          :class="{ 'ring-2 ring-red-500': passwordInvalid }"
        />
        <input
          type="submit"
          :value="loading ? 'Sletter bruker...' : 'Slett bruker'"
          :disabled="loading"
          class="px-10 py-4 mt-8 text-lg text-white bg-red-500 rounded-full disabled:opacity-50"
        />
      </form>
      <div class="flex-grow mt-6">
        <transition name="fade">
          <div v-show="error" class="text-center text-red-600">{{ errorMessage }}</div>
        </transition>
      </div>
    </div>
  </card-container>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import CardContainer from '../components/CardContainer.vue'

export default {
  name: 'UserDelete',
  components: { CardContainer },
  setup() {
    const store = useStore()
    const router = useRouter()

    const loading = ref(false)
    const error = ref(false)
    const errorMessage = ref('')

    const password = ref()
    const passwordInput = ref()
    const passwordInvalid = ref(false)

    const deleteUser = () => {
      loading.value = true
      error.value = false
      errorMessage.value = ''
      passwordInvalid.value = false

      store
        .dispatch('deleteUser', password.value)
        .then(() => {
          router.push({ name: 'menu', params: { transition: 'slide-forward' } })
        })
        .catch(err => {
          error.value = true
          loading.value = false
          password.value = ''
          passwordInput.value.focus()
          passwordInvalid.value = true
          switch (err.code) {
            case 'auth/argument-error':
              errorMessage.value = 'Passord er ikke riktig'
              break
            case 'auth/wrong-password':
              errorMessage.value = 'Passord er ikke riktig'
              break
            default:
              errorMessage.value = err.message
          }
        })
    }

    return {
      loading,
      error,
      errorMessage,
      password,
      passwordInput,
      passwordInvalid,
      deleteUser,
    }
  },
}
</script>
